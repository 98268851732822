.billing-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 80%;
}

/* .cc-container {
   display: flex;
   flex-direction: row;
   align-content: space-between;
   gap: 1rem;
} */

.cc-text {
    display: flex;
    flex-direction: column;
}

.payments-container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.trial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
}

.lifetime-license-container {
    width: 70%;
    border-radius: 1rem;
    border: 2px solid #FB2545;
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    text-align: left;
}

.recurring-license-container {
    border-radius: 1rem;
    border: 2px solid #FB2545;
    width: 70%;
    margin-top: 1rem;
    padding: 1rem 1.5rem 1.3rem 1.5rem
}

.active-license-container {
    border-radius: 1rem;
    border: 2px solid #FB2545;
    width: 70%;
    margin-top: 1rem;
    padding: 1rem 1.5rem

}

.upcoming-license-container {
    /* border-radius: 1rem;
    border: 2px solid #e1d6d7; */
    width: 70%;
    /* padding: 1rem 1.5rem */
}

.perptual-license-container {
    border-radius: 1rem;
    border: 2px solid #e1d6d7;
    width: 70%;
    margin-top: 1rem;
    padding: 1rem 1.5rem
}



.no-active-membership-container {
    margin-top: 5rem;
}

.edit-icon {
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 0.5rem;
    margin-left: auto;
}

.edit-icon:hover path, .edit-icon:hover rect {
    fill:var(--red);
}


.cancel-button {
    margin: auto auto 1.5rem auto;
}