.dashboard-container {
  padding-bottom: 4rem;
}
.dashboard-flex {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
}

.upcoming-charge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1rem;
}

.upcoming-charge-price {
  margin-top: 0.5rem;
}

.action-banner {
  height: 4rem;
  margin: 0 auto;
  background-color: #FB2645DD;

  display: flex;
  justify-content: center;
  align-items: center;
}
