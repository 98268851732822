/* ONBOARDING LAYOUT */
.onboarding-container {
    margin-top: 4.3rem;
    margin-left: 10%;
    color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    justify-content: start;
    min-width: 10rem;
    max-width: 35rem;
}

.onboarding-spacer {
    margin-top: 0.4rem;    /* trim some gap in onboarding-container */
}

h1.onboarding-header{
    font-size: 30px;
    font-weight: 600;
    text-align: left;
}

.onboarding-form {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    justify-content: start;
}

.onboarding-link {
    color:var(--red);
    text-decoration-color: var(--red);
}

.onboarding-link.login {
    margin-left: 4.3rem;
}


input[type='text'], [type='email'], [type='password'] {
    opacity: 1;
    border: 2px solid #32303B;
    border-radius: 4px;
    background-color: #201c24;
    color: rgba(255, 255, 255, 0.5);
    height: 3.0rem;
    font-size: var(--font);
    padding-left: 0.5rem;
    margin-bottom: 0.2rem;
    margin-right: 5rem;
    width: 20rem;
}


/* ANCHOR MOBILE */
@media only screen and (max-width: 500px ) {
    .onboarding-container {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 1rem 0 0 0;
    }

    input[type='text'], [type='email'], [type='password'] {
        height: 3.0rem;
        /* padding-left: 0.5rem; */
        margin: auto;
        width: 90%;
    }

    .onboarding-form {
        align-items: center;
        width: 90%;
    }
}